import * as React from 'react';
import { EGDSText } from '@egds/react-core/text';
import { EGDSIcon, EGDSIconSize } from '@egds/react-core/icons';
import { EGDSLink } from '@egds/react-core/link';
import { VacConfig } from 'common/constant';
export interface ErrorComponentProps {
  error: string;
  reloadVac: () => void;
}

export const VacErrorComponent: React.FC<ErrorComponentProps> = ({ error, reloadVac }: ErrorComponentProps) => {
  const errorConfig = VacConfig.errorConfig;
  const errorData = errorConfig[error];
  return error && errorData ? (
    <div className={'vacLoader'}>
      <EGDSIcon name={errorData.iconName} size={EGDSIconSize.XLARGE} />

      <EGDSText align="center" className="vacError-header">
        {' '}
        {errorData.errorHeader}{' '}
      </EGDSText>

      <EGDSText align="center" className="vacError-subheader">
        {' '}
        {errorData.errorSubheader}{' '}
      </EGDSText>

      {errorData.refreshButton?.enabled ? (
        <EGDSLink align="center" onClick={reloadVac}>
          <EGDSText>{errorData.refreshButton?.buttonText}</EGDSText>
        </EGDSLink>
      ) : null}
    </div>
  ) : null;
};

VacErrorComponent.displayName = 'VacErrorComponent';
