import * as React from 'react';
import { Viewport, ViewSmall, ViewMedium } from '@shared-ui/viewport-context';
import { openChatWidget } from '@shared-ui/customer-virtual-agent-control';
import { gql } from '@apollo/client';
import { IntentConfigFieldsFragment } from 'common/__generated__/api/types';
import { FragmentComponent } from 'components/component-types';
import { message } from 'src/components/VirtualAgentControl/virtual-agent-control-types';
import { EVENTS } from 'utils/logger-events';
import { ClientLogger } from 'bernie-client';
import { inject } from 'mobx-react';
import { EGDSCard, EGDSCardContentSection, EGDSCardLink } from '@egds/react-core/cards';
import { EGDSLayoutFlex, EGDSLayoutFlexItem } from '@egds/react-core/layout-flex';
import { EGDSIcon } from '@egds/react-core/icons';
import { EGDSText } from '@egds/react-core/text';
import { egClickStreamConstants } from 'common/constant';
import { useEgClickstream } from '@shared-ui/clickstream-analytics-context';
import { VirtualAgentControlStore } from 'stores/virtual-agent-control-store';
import { prepareClickStreamPayload } from 'common/helper';
import { useVirtualAgentContext } from '@shared-ui/retail-help-center-util';

interface IntentButtonsProps {
  enabled: boolean;
  data: IntentConfigFieldsFragment;
}

const fragment = gql`
  fragment IntentConfigFields on HelpCenterPersonalizationData {
    intentButtonsHeading
    intentButtons {
      name {
        icon {
          id
          token
          description
        }
        primary
      }
      skillName
    }
  }
`;

const logger = ClientLogger.getLoggerWithIdentifier('intent-button');

export interface IntentButtonProps {
  icon_name?: string;
  button_type?: string;
  name: string;
  enabled: boolean;
  virtualAgentControlStore?: VirtualAgentControlStore;
  onClick: (msg: message) => void;
}

export const InternalIntentButton = inject('virtualAgentControlStore')(
  ({ icon_name, button_type, enabled, virtualAgentControlStore, name, onClick }: IntentButtonProps) => {
    const trackClick = useEgClickstream();
    const {
      egClickStreamEventNames,
      egClickStreamEventTypes,
      egClickStreamEventCategories,
      egClickStreamEventActionLocation,
    } = egClickStreamConstants;
    const clickStreamEvent = prepareClickStreamPayload(
      virtualAgentControlStore,
      egClickStreamEventNames.intentButtonSelected,
      egClickStreamEventTypes.interaction,
      egClickStreamEventCategories.helpCenter,
      egClickStreamEventActionLocation.intentButtons,
      undefined,
      name
    );
    const message: message = {
      messageType: 'POSTBACK',
      text: name,
      intent: button_type,
    };
    return (
      <EGDSCard className={`button-margin ${enabled ? '' : 'button-disabled'}`} data-stid={'inner-intent'}>
        <EGDSCardContentSection padded={false}>
          <EGDSLayoutFlex>
            <EGDSLayoutFlexItem alignSelf="center" grow={0}>
              <div className="icon-padding">
                <EGDSIcon name={icon_name} spotlight="standard" />
              </div>
            </EGDSLayoutFlexItem>
            <EGDSLayoutFlexItem grow={1}>
              <div className="button-text" aria-hidden="true">
                <EGDSText size={500} className="button-text-color">
                  {name}
                </EGDSText>
              </div>
            </EGDSLayoutFlexItem>
          </EGDSLayoutFlex>
        </EGDSCardContentSection>
        {enabled ? (
          <Viewport>
            <ViewSmall>
              <EGDSCardLink>
                <button
                  onClick={() => {
                    trackClick(clickStreamEvent);
                    onClick(message);
                  }}
                >
                  {name}
                </button>
              </EGDSCardLink>
            </ViewSmall>
            <ViewMedium>
              <EGDSCardLink>
                <button
                  onClick={() => {
                    trackClick(clickStreamEvent);
                    onClick(message);
                  }}
                >
                  {name}
                </button>
              </EGDSCardLink>
            </ViewMedium>
          </Viewport>
        ) : null}
      </EGDSCard>
    );
  }
);

export const IntentButton: FragmentComponent<IntentButtonsProps, IntentConfigFieldsFragment> = (
  props: IntentButtonsProps
) => {
  const { controller } = useVirtualAgentContext();
  const experienceIntentButtonData = props.data.intentButtons;
  const onClick = (msg: message) => {
    logger.logEvent(EVENTS.INTENT_BUTTON_CLICK, { message: `calling sendMessage for ${msg.intent}` });
    controller.sendMessage(msg);
    openChatWidget();
  };
  return (
    <>
      {experienceIntentButtonData?.map((intentButton, index) => {
        return (
          <>
            <InternalIntentButton
              key={index}
              icon_name={intentButton.name.icon.id}
              button_type={intentButton.skillName}
              enabled={props.enabled}
              name={intentButton.name.primary}
              onClick={onClick}
            />
          </>
        );
      })}
    </>
  );
};
IntentButton.fragment = fragment;
IntentButton.displayName = 'IntentButtons';
